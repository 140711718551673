import dynamic from 'next/dynamic'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useTheme } from '../../context/theme'

const DMPrimaryButton = dynamic(() => import('@decormatters/dm-theme').then(component => component.DMPrimaryButton), { ssr: false })
const DMPrimaryAltButton = dynamic(() => import('@decormatters/dm-theme').then(component => component.DMPrimaryAltButton), { ssr: false })
const DMModal = dynamic(() => import('@decormatters/dm-theme').then(component => component.DMModal), { ssr: false })

import { uploadMyRoom } from '../../redux/apis'
import { setModalClose, setModalOpen } from '../../redux/designerReducers/designerReducer'

import 'react-image-crop/dist/ReactCrop.css'

import useModal from '../../hooks/useModal'
import { logEvent } from '../../utils/firebase'
import Portal from '../DesignerCommon/portal'
import ModalActionContainer from './ModalActionContainer'
import ModalContentContainer from './ModalContentContainer'

const ViewImgContainer = styled.div`
  /*text-align: center;*/
`

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
`

const CropRoomModal = forwardRef(({ show, onCompleteForDelayedSave, onClose, onComplete, ...props }, ref) => {
  const dispatch = useDispatch()
  const { appearance } = useTheme()
  useModal(show)

  const rImg = useRef(null)

  const pixelRatio = 4
  const [image, setImage] = useState()
  const [crop, setCrop] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [completedCrop, setCompletedCrop] = useState(null)

  //const [viewImgHeight, setViewImgHeight] = useState('600px')
  const [viewImgWidth, setViewImgWidth] = useState('600px')

  const VIEW_MAX_HEIGHT = 600
  const VIEW_MAX_WIDTH = 600

  const squareCrop = useCallback(
    ({ w, h }) => {
      if (show) {
        if (!w) w = rImg.current.width
        if (!h) h = rImg.current.height

        const short = w > h ? h : w
        const aspect = 1 / 1
        const x = Math.abs(w / 2 - short / 2)
        const y = Math.abs(h / 2 - short / 2)

        const c = {
          width: short,
          x,
          y,
          aspect
        }

        setCrop(c)
        setCompletedCrop(c)
      }
    },
    [show]
  )

  useEffect(() => {
    window.addEventListener('resize', squareCrop)
    return function cleanup() {
      window.removeEventListener('resize', squareCrop)
    }
  }, [image, squareCrop])

  const cropImage = () => {
    if (!completedCrop || !rImg.current) return

    const image = rImg.current

    const canvas = document.createElement('canvas')
    const crop = completedCrop
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const ctx = canvas.getContext('2d')

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingEnabled = false

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height)

    const dataUrl = canvas.toDataURL('image/jpeg', 0.8)

    return dataUrl
  }

  const dataURItoBlob = dataURI => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString
    if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
    else byteString = unescape(dataURI.split(',')[1])

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length)
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }

    return new Blob([ia], { type: mimeString })
  }

  const onLoad = useCallback(
    img => {
      rImg.current = img

      var w = img.naturalWidth
      var h = img.naturalHeight

      if (w > VIEW_MAX_WIDTH || h > VIEW_MAX_HEIGHT) {
        if (w >= h) {
          const sx = VIEW_MAX_WIDTH / w
          //setViewScale(sx)
          setViewImgWidth(VIEW_MAX_WIDTH + 'px')
          const sh = sx * h
          //setViewImgHeight(sh + 'px')
          w = VIEW_MAX_WIDTH
          h = sh
        } else {
          const sy = VIEW_MAX_HEIGHT / h
          //setViewScale(sy)
          //setViewImgHeight(VIEW_MAX_HEIGHT + 'px')
          const sw = sy * w
          setViewImgWidth(sw + 'px')
          w = sw
          h = VIEW_MAX_HEIGHT
        }
      } else {
        //setViewScale(1.0)
        setViewImgWidth(w + 'px')
        //setViewImgHeight(h + 'px')
      }

      squareCrop({ w, h })
      return false
    },
    [squareCrop]
  )

  const handleOk = async () => {
    //const cropped = cropImage()
    //const blob = dataURItoBlob(cropped)
    //var file = new File([blob], 'myroom.jpeg', { type: 'image/jpeg' })
    logEvent('my_room_upload_complete', {
      content_type: 'my_room'
    })

    setSubmitting(true)
    if (!image) {
      //rCrop.current.hide()
      setSubmitting(false)
      return
    }

    const cropped = cropImage()
    const blob = dataURItoBlob(cropped)
    var file = new File([blob], 'myroom.jpeg', { type: 'image/jpeg' })

    dispatch(setModalClose())

    if (onCompleteForDelayedSave) {
      setSubmitting(false)
      onCompleteForDelayedSave(file)
      return
    }

    const result = await uploadMyRoom(file)

    //rCrop.current.hide()
    if (onComplete && result?.result?.newUserTemplate?.cfImageUrl) onComplete(result?.result?.newUserTemplate?.cfImageUrl)

    setSubmitting(false)
  }

  useImperativeHandle(ref, () => ({
    setImage: imgfile => {
      setImage(imgfile)
    },
    show: imgfile => {
      setImage(imgfile)
      //rCrop.current.show()
      dispatch(setModalOpen())
    },
    hide: () => {
      dispatch(setModalClose())
      //rCrop.current.hide()
    },
    showAndSave: async imgfile => {
      if (!imgfile) {
        if (onClose) onClose()
        else dispatch(setModalClose())
        return
      }
      setSubmitting(true)
      const result = await uploadMyRoom(imgfile)

      setSubmitting(false)

      if (onComplete) onComplete()
    }
  }))

  if (!show) return null

  return (
    <Portal>
      <ModalWrapper>
        <DMModal show={show} title="Upload MyRoom" scheme={appearance} maxWidth={800} noOverlayClose onClose={onClose} size={DMModal.MODAL_SIZE_FULLSCREEN} {...props}>
          <ModalContentContainer>
            <ViewImgContainer draggable="false">
              <ReactCrop
                src={image}
                onImageLoaded={onLoad}
                crop={crop}
                minWidth={150}
                minHeight={150}
                keepSelection={true}
                ruleOfThirds={true}
                onChange={c => setCrop(c)}
                onComplete={c => setCompletedCrop(c)}
                imageStyle={{
                  width: viewImgWidth,
                  backgroundColor: 'white'
                }}
              />
            </ViewImgContainer>
            <ModalActionContainer>
              <DMPrimaryAltButton scheme={appearance} type="button" onClick={onClose} style={{ marginRight: '10px' }} disabled={submitting}>
                Cancel
              </DMPrimaryAltButton>
              <DMPrimaryButton scheme={appearance} type="button" onClick={handleOk} style={{ marginLeft: '10px' }} disabled={submitting}>
                Ok
              </DMPrimaryButton>
            </ModalActionContainer>
          </ModalContentContainer>
        </DMModal>
      </ModalWrapper>
    </Portal>
  )
})

export default CropRoomModal
