import moment from 'moment'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ButtonNext, Slide, Slider } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import CoinsIcon from '../../assets/Coins.svg'
import ArrowIcon from '../../assets/arrow-down.svg'
import MembershipIcon from '../../assets/badge_membership_on.png'
import CoinIcon from '../../assets/coin.svg'
import LockIcon from '../../assets/lock.svg'
import NoItems from '../../assets/noitems.png'
import BrandReqIcon from '../../assets/userEvent/brandReq.svg'
import ColorReqIcon from '../../assets/userEvent/colorReq.svg'
import RoomTypeReqIcon from '../../assets/userEvent/roomtypeReq.svg'
import TimeoutIcon from '../../assets/userEvent/timeout.svg'
import WinBadgeIcon from '../../assets/userEvent/winBadge.svg'
import WinnerIcon from '../../assets/userEvent/winner.svg'
import { LoadingIndicator } from '../../components/Loading/LoadingIndicator'
import { queryClient } from '../../pages/_app'
import { useUserEventDetails } from '../../queries/events'
import { getPrerenderedPost } from '../../queries/users'
import { saveUserEvent } from '../../redux/apis'
import { FEED_RESET } from '../../redux/constants'
import { fetchDidJoinUserEvent } from '../../redux/designerReducers/challengeReducer'
import { setShowMembershipModal } from '../../redux/designerReducers/designerReducer'
import { fetchSaved, setSavedLikes } from '../../redux/designerReducers/userSavedReducer'
import { fetchFeed, setAttr } from '../../redux/feedInfiniteSlice'
import { getMembershipType } from '../../utils/getMembershipType'
import { getMyEventCountdown } from '../../utils/myEventDate'
import DesignFeedCard from '../DesignerDesigns/cards/DesignFeedCard'
import JoinedDesignCard from '../DesignerDesigns/cards/JoinedDesignCard'
import NestedFeed from '../DesignerFeed/NestedFeed'
import { DMTabs } from '../DesignerTheme/DMTabs'
import EventStatusProgressBar from '../EventStatusProgressBar'
import UserEventLevelLimitationModal from '../UserEventLevelLimitationModal'
import BackButton from '../navs/BackButton'
import { AreYouSureWindow } from './AreYouSureWindow'
import {
  BackButtonWrapper,
  BigActionButton,
  CarouselWrapper,
  Content,
  Countdown,
  DesignsContainer,
  DesignsGrid,
  DesignsHeader,
  DesignsTitle,
  EventDetailWrapper,
  EventProgressContainer,
  HashtagTag,
  HostSection,
  ImageTag,
  JoinedLabel,
  LeftPart,
  LevelLimitationOverlay,
  LoaderWrapper,
  NextButton,
  PrevButton,
  PreviewHeader,
  RequirementTag,
  RewardLine,
  Rewards,
  RightPart,
  SlideImage,
  Subheader,
  TagsGrid
} from './EventDetailView.styles'
import { PreviewPostWindow } from './PreviewPostWindow'
import { DesignSubmissionCard, WinnersSection } from './WinnersSection'

import dynamic from 'next/dynamic'
import { NoItemsContainer, NoItemsImg, NoItemsInfo } from '../../routes/Challenge'
const DMShareModal = dynamic(() => import('@decormatters/dm-theme').then(component => component.DMShareModal), { ssr: false })

const tabs = [
  { name: 'Newest', value: 'newest' },
  { name: 'Popular', value: 'popular' }
]

const Card = props => {
  return (
    <DesignFeedCard sizeless>
      <JoinedDesignCard {...props}></JoinedDesignCard>
    </DesignFeedCard>
  )
}

export const EventDetailView = ({ eventId, hideBackButton }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { feed, error, loading } = useSelector(state => state.otherDesigns)
  const [selectedTab, setSelectedTab] = useState(tabs[0].value)
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)
  const [participationInfo, setParticipationInfo] = useState(null)
  const [showLevelLimitationModal, setShowLevelLimitationModal] = useState(false)
  const request = useUserEventDetails(eventId)
  const { isLoading, data } = request
  const profile = useSelector(state => state.profile?.me)
  const membershipInfo = getMembershipType(profile)
  const [winners, setWinners] = useState(['', '', ''])
  const [previewPost, setPreviewPost] = useState(null)
  const [isEditWinnersMode, setIsEditWinnersMode] = useState(false)
  const rShareModal = useRef()

  const creatorId = data?.user?.objectId
  const isEventCreator = creatorId === profile?.objectId
  const hasAccessToEvent = membershipInfo?.isMember || profile?.userLevel >= 3 || profile?.level >= 3

  useEffect(() => {
    dispatch(fetchDidJoinUserEvent(eventId)).then(({ payload }) => {
      setParticipationInfo(payload)
    })
  }, [eventId])

  useEffect(() => {
    if (!eventId) return
  }, [eventId])

  useEffect(() => {
    const attr = {
      addition: {
        userEventId: eventId
      }
    }

    if (selectedTab === 'popular') {
      attr = {
        addition: {
          userEventId: eventId,
          mostRecentCreatedAt: new Date(),
          sort: 'numLikes'
        }
      }
    }

    batch(() => {
      dispatch(fetchSaved())
      dispatch(setAttr('otherDesigns')(attr))
      dispatch(fetchFeed('otherDesigns')({ status: FEED_RESET })).then(async r => {
        if (r.payload && r.payload.data && r.payload.data.currentUserlikes) {
          dispatch(setSavedLikes(r.payload.data.currentUserlikes))

          let winners = []

          r.payload.data.userInspirations.forEach(item => {
            if (data?.winners?.includes(item.objectId)) {
              winners.push(item)
            }
          })

          if (winners.length < data?.winners?.length) {
            const foundItems = winners.map(item => item.objectId)
            const posts = await Promise.all(data?.winners?.filter(item => !foundItems.includes(item))?.map(item => getPrerenderedPost(item)))

            winners = winners.concat(posts.map(item => item?.result?.userInspiration))
          }

          setWinners(winners)
        }
      })
    })
  }, [selectedTab, eventId, dispatch, data?.winners, isEventCreator])

  const winnerIds = useMemo(() => {
    const ids = []

    winners.forEach(item => {
      if (item) ids.push(item.objectId)
    })

    return ids
  }, [winners])

  const handleFetch = e => {
    dispatch(fetchFeed('otherDesigns')({ status: null })).then(r => {
      if (r.payload && r.payload.data && r.payload.data.currentUserlikes) dispatch(setSavedLikes(r.payload.data.currentUserlikes))
    })
  }

  const eventStatus = useMemo(() => {
    if (data?.status === 'active' && moment(data.featuredAt.iso).isAfter(moment())) {
      return 'Event is reviewed and will be live soon.'
    }

    switch (data?.status) {
      case 'submitted':
      case 'reviewing':
        return 'Your event is under review. You will receive a notification once it has been approved.'
      case 'active':
        return 'Your event is live! You can select 3 winners during the live time.'
      case 'winnerSelection':
        return 'Your event has ended. Please select the winners within 24 hours.'
      default:
        return ''
    }
  }, [data?.status])

  const actionButtonDisabled = useMemo(() => {
    if (!isEventCreator && moment(data?.expiresAt?.iso).isBefore(moment())) return true
    if (participationInfo?.userInsId && !isEventCreator) return true

    if (data?.status === 'active' && moment(data.featuredAt.iso).isAfter(moment())) return true

    switch (data?.status) {
      case 'submitted':
      case 'reviewing':
        return true
      case 'active':
        // if ((profile.userLevel || 0) < 3) return true

        return false
      case 'winnerSelection':
        return !isEventCreator
      case 'closed':
        return true
      default:
        return true
    }
  }, [data?.status, winnerIds, participationInfo, isEventCreator])

  const actionButtonLabel = useMemo(() => {
    if (moment(data?.expiresAt?.iso).add(24, 'hours').isBefore(moment())) {
      return 'Event Closed'
    }

    if (isEventCreator) {
      if (isEditWinnersMode) return `Confirm Winners (${winnerIds.length}/3)`

      if (data?.winners && data?.winners.length > 0) return 'Revise Winners'
    }

    if (data?.status === 'active' && moment(data.featuredAt.iso).isAfter(moment())) {
      return 'Event Scheduled'
    }

    if (participationInfo?.userInsId && !isEventCreator && data?.status === 'active') return 'Joined'

    if (participationInfo?.ideaObjectId && !isEventCreator && data?.status === 'active') return 'Continue Design'

    switch (data?.status) {
      case 'submitted':
      case 'reviewing':
        return 'Under Review'
      case 'active':
        if (isEventCreator && data?.winners && data?.winners.length > 0 && !isEditWinnersMode) return 'Revise Winners'

        if ((profile.userLevel || 0) < 3 && !membershipInfo.isMember) {
          return 'Members or Level 3+'
        }

        return isEventCreator ? 'Select Winners' : 'Join Now'
      case 'winnerSelection':
      case 'closed':
        return 'Event Closed'
      default:
        return ''
    }
  }, [data?.status, participationInfo, winnerIds, isEventCreator, isEditWinnersMode])

  const handleSelectWinners = useCallback(async () => {
    try {
      if (!isEventCreator) return

      const winnerIds = winners.map(item => item.objectId)

      await saveUserEvent({
        objectId: eventId,
        winners: winnerIds.filter(item => item)
      })

      queryClient.invalidateQueries('GET_USER_EVENT_DETAILS')
    } catch (error) {
      alert('Error happened. Please, try again.')
    }

    setIsConfirmationVisible(false)
  }, [isEventCreator, winners])

  useEffect(() => {
    if (!router.query.designs) return
    document.getElementById('design-section')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    })
  }, [router.query.designs])

  const actionButtonHandler = useCallback(() => {
    if (actionButtonDisabled) return

    if ((profile.userLevel || 0) < 3 && !membershipInfo.isMember) {
      dispatch(setShowMembershipModal(true))

      return
    }

    if (isEventCreator && data?.winners && data?.winners.length > 0 && data?.status !== 'closed' && !isEditWinnersMode) {
      document.getElementById('design-section')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start'
      })

      setIsEditWinnersMode(true)

      return
    }

    if (!hasAccessToEvent) {
      setShowLevelLimitationModal(true)

      return
    }

    switch (data?.status) {
      case 'submitted':
      case 'reviewing':
      case 'winnerSelection':
        setIsConfirmationVisible(true)

        return
      case 'active':
        if (isEventCreator) {
          if (winnerIds.length === 0) {
            document.getElementById('design-section')?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'start'
            })

            setIsEditWinnersMode(true)

            return
          }

          if (winnerIds.length < 3) {
            setIsConfirmationVisible(true)

            return
          }

          handleSelectWinners()

          setIsEditWinnersMode(false)

          return
        }

        const challengeData = data

        if (!challengeData) return

        router.push(`/create/userEvent/${challengeData.objectId}`)

        return
      default:
        return ''
    }
  }, [data, router, profile, hasAccessToEvent, actionButtonDisabled, winnerIds, handleSelectWinners, isEditWinnersMode])

  const eventCountdownText = useMemo(() => {
    return getMyEventCountdown(data)
  }, [data])

  if (isLoading) return null

  const LevelLimitationWarning = () => {
    if (profile.userLevel > 3 || profile.level > 3 || membershipInfo.isMember) return null

    return (
      <LevelLimitationOverlay>
        <img src={LockIcon} alt="" />
        <span>
          All Events Are
          <br />
          Members or Level 3+ Only
        </span>
      </LevelLimitationOverlay>
    )
  }

  const handleToggleSelected = design => {
    const emptyArr = ['', '', '']
    const newWinners = winners.filter(item => item)

    if (newWinners.find(item => item.objectId === design.objectId)) {
      const newArr = [...newWinners.filter(item => item.objectId !== design.objectId), ...emptyArr].slice(0, 3)

      setWinners(newArr)

      return
    }

    const newArr = [design, ...newWinners, ...emptyArr].slice(0, 3)

    setWinners(newArr)
  }

  const handleShare = (lnk, ttl, img, pos) => {
    rShareModal.current.show(lnk, ttl, img, pos)
  }

  const isClosed = data?.status === 'closed'

  if (!data) return null

  return (
    <>
      <DMShareModal ref={rShareModal} />
      <EventDetailWrapper>
        {!hideBackButton && (
          <BackButtonWrapper>
            <BackButton autoPosition={false} />
          </BackButtonWrapper>
        )}
        <Content>
          <LeftPart>
            <PreviewHeader>
              <div>
                <h2>{data.title}</h2>
                <Countdown>
                  <img src={TimeoutIcon} alt="" />
                  <span>{eventCountdownText}</span>
                </Countdown>
              </div>
              <Link href={`/dm/${data.user?.objectId}`}>
                <HostSection>
                  <img src={data.user?.funAvatarImageUrl || data.user?.thumbProfileImageFile?.url || data.user?.cfTbImageUrl || 'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'} alt="" />
                  <span>Host</span>
                </HostSection>
              </Link>
            </PreviewHeader>
            <CarouselWrapper naturalSlideWidth={494} naturalSlideHeight={494} totalSlides={2}>
              <PrevButton>
                <img src={ArrowIcon} alt="" />
              </PrevButton>
              <NextButton as={ButtonNext}>
                <img src={ArrowIcon} alt="" />
              </NextButton>
              <Slider>
                <Slide index={0}>
                  <ImageTag>Inspiration</ImageTag>
                  <SlideImage src={data.inspirationImage} alt="" />
                  <LevelLimitationWarning />
                </Slide>
                <Slide index={1}>
                  <ImageTag>Template</ImageTag>
                  <SlideImage src={data.templateImage} alt="" />
                  <LevelLimitationWarning />
                </Slide>
              </Slider>
              <JoinedLabel>
                {(feed || []).slice(0, 3).map(data => (
                  <img src={data?.user?.funAvatarImageUrl || data?.user?.thumbProfileImageFile?.url || 'https://didr9pubr8qfh.cloudfront.net/mobile_other/profile_avatars/Profile5.png'} />
                ))}
                {feed.length > 3 && <div>...</div>}
                <span>{data?.numEntries || 0} joined</span>
              </JoinedLabel>
              {/* <Dots /> */}
            </CarouselWrapper>
            {isEventCreator && (
              <EventProgressContainer>
                <Subheader>Status</Subheader>
                <p>{eventStatus}</p>
                <EventStatusProgressBar expiresAt={data?.expiresAt} status={data?.status} featuredAt={data?.featuredAt?.iso} />
              </EventProgressContainer>
            )}
          </LeftPart>
          <RightPart>
            <Subheader>Rewards</Subheader>
            <Rewards>
              <RewardLine>
                <div>
                  <img src={CoinIcon} width={24} alt="" />
                </div>
                All other participants will earn 100 Dcoins.
              </RewardLine>
              <RewardLine>
                <div>
                  <img src={CoinsIcon} width={35} alt="" />
                </div>
                Win the event to earn 2000 Dcoins.
              </RewardLine>
              <RewardLine>
                <div>
                  {' '}
                  <img src={WinBadgeIcon} alt="" />
                </div>
                Win the event to earn a Event Winner badge.
              </RewardLine>
            </Rewards>
            <Subheader>Instructions</Subheader>
            <Rewards>
              <RewardLine>
                <div>
                  <img src={MembershipIcon} width={28} alt="" />
                </div>
                Members or level 3+ can join the event
              </RewardLine>
              <RewardLine>
                <div>
                  <img src={WinnerIcon} width={28} alt="" />
                </div>
                Host will pick 3 winners from all designs within 24 hours after the event ends.
              </RewardLine>
            </Rewards>
            {data.description && (
              <>
                <Subheader>Description</Subheader>
                <p>{data.description}</p>
              </>
            )}
            {data.requirements && typeof data.requirements === 'object' && Object.keys(data.requirements).length > 0 && (
              <>
                <Subheader>Requirements</Subheader>
                <TagsGrid>
                  {data?.requirements?.color &&
                    data?.requirements?.color.map(color => (
                      <RequirementTag key={color} background="#FAECEC">
                        <img src={ColorReqIcon} alt="" />
                        Color: {color}
                      </RequirementTag>
                    ))}
                  {data?.requirements?.category &&
                    data?.requirements?.category.map(category => (
                      <RequirementTag key={category.shortName} background="#FFF3C0">
                        <img src={RoomTypeReqIcon} alt="" />
                        Room type: {category.shortName}
                      </RequirementTag>
                    ))}
                  {data?.requirements?.brand &&
                    data?.requirements?.brand.map(brand => (
                      <RequirementTag key={brand.storeName} background="#D3DFF9">
                        <img src={BrandReqIcon} alt="" />
                        Brand: {brand.storeName}
                      </RequirementTag>
                    ))}
                </TagsGrid>
              </>
            )}
            {data.hashtags.length > 0 && (
              <>
                <Subheader>Tags</Subheader>
                <TagsGrid>
                  {data.hashtags.map(tag => (
                    <Link href={`/hashtag/${tag}`} key={tag}>
                      <HashtagTag>#{tag}</HashtagTag>
                    </Link>
                  ))}
                </TagsGrid>
              </>
            )}
          </RightPart>
        </Content>
        <BigActionButton onClick={actionButtonHandler} disabled={actionButtonDisabled}>
          {actionButtonLabel}
        </BigActionButton>
        {(isEventCreator || data?.status === 'closed') && (
          <DesignsContainer>
            <DesignsHeader>
              <DesignsTitle>Winners ({winnerIds.length}/3)</DesignsTitle>
            </DesignsHeader>
            <DesignsGrid winners>
              <WinnersSection isEditWinnersMode={isEditWinnersMode} defaultWinners={data?.winners} closed={isClosed} winners={winners} onToggleSelected={handleToggleSelected} />
            </DesignsGrid>
          </DesignsContainer>
        )}
        <DesignsContainer>
          <DesignsHeader>
            <DesignsTitle id="design-section">Joined Designs ({data.numEntries || 0})</DesignsTitle>
            <DMTabs tabs={tabs} value={selectedTab} onChange={e => setSelectedTab(e)} />
          </DesignsHeader>
          <DesignsGrid designs>
            {loading !== 'idle' ? (
              <LoaderWrapper>
                <LoadingIndicator loading={true} />
              </LoaderWrapper>
            ) : (
              <>
                {feed && feed?.length === 0 && (
                  <NoItemsContainer scheme="light">
                    <NoItemsImg src={NoItems} alt={'no-items'} />
                    <NoItemsInfo>No Design Yet</NoItemsInfo>
                  </NoItemsContainer>
                )}
                <NestedFeed
                  data={feed}
                  error={error}
                  loading={loading}
                  card={
                    isEventCreator ? props => <DesignSubmissionCard closed={isClosed} onToggleSelected={handleToggleSelected} {...props} winners={winners} isEditWinnersMode={isEditWinnersMode} onPreview={() => setPreviewPost(props.data)} /> : Card
                  }
                  onFetch={handleFetch}
                  onShare={handleShare}
                />
              </>
            )}
          </DesignsGrid>
        </DesignsContainer>
      </EventDetailWrapper>
      {showLevelLimitationModal && <UserEventLevelLimitationModal onClose={() => setShowLevelLimitationModal(false)} />}
      {isConfirmationVisible && (
        <AreYouSureWindow
          final={data.status === 'selectWinners'}
          onClose={() => setIsConfirmationVisible(false)}
          onConfirm={() => {
            handleSelectWinners()

            setIsEditWinnersMode(false)
          }}
        />
      )}
      {previewPost && <PreviewPostWindow onClose={() => setPreviewPost(null)} winners={winnerIds} post={previewPost} isEditWinnersMode={isEditWinnersMode} onToggle={() => handleToggleSelected(previewPost)} />}
    </>
  )
}
