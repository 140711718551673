import dmIcon from '../../assets/dmIconCircle.png'
import { logEvent } from '../../utils/firebase'
import { GetAppButtonWrapper, IconWrapper } from './GetAppButton.styles'

export const GetAppButton = () => {
  return (
    <GetAppButtonWrapper
      onClick={() => {
        logEvent('get_the_app_button_click', {})
        window.open('https://decormatters.app.link/download')
      }}
    >
      <IconWrapper>
        <img src={dmIcon} alt="" />
      </IconWrapper>
      <span>Get the app</span>
    </GetAppButtonWrapper>
  )
}
