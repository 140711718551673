import dynamic from 'next/dynamic'
import tooltipSparks from '../../assets/tooltipSparks.svg'
import * as animationData from './mouseClick.json'
import { TooltipContainer, TooltipSkipButton, TooltipSubtitle, TooltipWrapper } from './TutorialTooltip.styles'
const Lottie = dynamic(() => import('react-lottie'), { ssr: false })

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export const TutorialTooltip = ({ continuous, index, showSkip, step, backProps, closeProps, primaryProps, tooltipProps, handleSkipClick, showSkipConfirmation }) => {
  return (
    <TooltipWrapper top={step.top} left={step.left} right={step.right} right={step.bottom} reverse={step.reverse} {...tooltipProps}>
      <Lottie
        style={{
          position: 'absolute',
          top: step.reverse ? 100 : -70,
          right: step.clickRight || undefined,
          left: step.clickLeft || undefined,
          bottom: step.clickBottom || undefined,
          zIndex: 1000,
          pointerEvents: 'none'
        }}
        options={defaultOptions}
        height={100}
        width={100}
      />
      <img src={tooltipSparks} alt="" />
      {showSkip && <TooltipSkipButton onClick={handleSkipClick}>Skip</TooltipSkipButton>}
      <TooltipContainer>
        <div>{step.content}</div>
        {step.title && <TooltipSubtitle>{step.title}</TooltipSubtitle>}
      </TooltipContainer>
    </TooltipWrapper>
  )
}
